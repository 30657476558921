'use strict';

// ПОДКЛЮЧЕНИЕ
const $ = require('./jquery');
const jQuery = $;
window.jQuery = $;

// мобильное меню
const mobileMenu = require('./mobileMenu');
// слайдер
const slider = require('./simpleSlider');
// попапы
let popupReview = require('./popupReview');
// автоформы
const autoforms = require('./autoforms');
// отправка форм по аякс
const forms = require('./forms');
// цены
const accounting = require('./accounting');
// scrol to
let autoScrollToTag = require('./autoScrollToTag');
// пересчитываемые цифры
let mathAppear = require('./mathAppear');
// maskedInput
const maskedInput = require('./maskedinput');
// табы
let simpleTab = require('./simpleTab');
// калькулятор частной газификации
let chCalc = require('./chCalc');
// калькулятор аренды
let arendaCalc = require('./arendaCalc');
// передача данных из форм калькулятора в форму попапа
let whiteForm = require('./whiteForm');
// калькулятор стоимости отопления
const mainCalc = require('./mainCalc');
// акордеон
let simpleAccord = require('./simpleAccord');
const { checkWebp } = require('./check-webp');
const { flipCard } = require('./flipCard');
const { default: customSelect } = require('./customSelect');
const { selectFilter } = require('./select-filter');

// НАСТРОЙКИ

checkWebp();

// слайдер
slider({
  rootTag: '.js-order-slider',
  slideItemTag: '.js-inner > *',
  prev: '.js-prev',
  next: '.js-next',
  controls: '',
  interval: '',
  slideFromAbsolute: false,
  stopOnHover: false,
});

// мобильное меню
mobileMenu({
  btnOpenTag: '.js-btn-mobile-menu-open',
  btnCloseTag: '.js-btn-mobile-menu-close',
  bodyClass: 'is-menu-open',
});

// вопросы форма
mobileMenu({
  btnOpenTag: '.js-qa-popup-open',
  btnCloseTag: '.js-qa-popup-close',
  bodyClass: 'is-qa-popup-open',
});

// подсказки
popupReview({
  itemTag: '.js-help-item',
  btnTag: '.js-help-open',
  closeTag: '.js-help-close',
});

// плавный скрол к якорям
autoScrollToTag({
  itemTag: '.js-scroll',
  duration: '50',
  speed: '1000',
  ofset: 0,
  parentTag: '',
  noParentOfsetLessWhen: 0,
});

// Форматирование чисел в цене
let priceList = document.querySelectorAll('.js-price');
priceList = Array.from(priceList);

priceList.forEach((item) => {
  let str = Math.round(item.innerHTML);
  str = accounting.formatNumber(str);
  item.innerHTML = str;
});

// Обрабатываем телефон
let telList = document.querySelectorAll('.js-tel');
telList = Array.from(telList);
telList.forEach(function (telInp) {
  maskedInput(telInp).mask('+7 (999) 999-99-99');
});

// Автоформы
let autoformList = document.querySelectorAll('.js-autoform');
autoformList = Array.from(autoformList);

autoformList.forEach(function (form) {
  autoforms.init(form, {
    Validators: {
      maskphone: {
        keys: '1234567890',
        errorMessage: 'Type only numbers',
        validatorFunction: function validatorFunction(field) {
          return (field.valid = field.nodeLink.value.indexOf('_') < 0);
        },
        keypressValidatorFunction: false,
      },
    },
  });
});

// отправка форм по аякс
forms('.js-form');

// табы
simpleTab({
  rootTag: '.js-tabs',
  controlGroupTag: '.js-tab-menu',
  nameTag: '.js-tab-menu-item',
  itemTag: '.js-tab-item',
});

// калькулятор частной газификации
chCalc({
  rootTag: '.js-ch-calc-root',
  basicPriceInputTag: '.js-ch-calc-basic-price',
  optionInputTag: '.js-ch-calc-option',
  totalPriceTag: '.js-ch-calc-total-price',
});

// калькулятор аренды
arendaCalc({
  rootTag: '.js-arenda-card',
  optionInputTag: '.js-arenda-option',
  totalPriceTag: '.js-arenda-total-price',
});

// Калькулятор стомиости отопления
mainCalc();

whiteForm();

(function globalAnimation() {
  let body = document.querySelector('body');
  setTimeout(() => {
    body.classList.add('is-global-animation');
  }, 100);
})();

//
simpleAccord({
  rootTag: '.js-qa',
  itemTag: '.js-qa-item',
  toggleTag: '.js-qa-toggle',
  scroll: false,
  ofset: 0,
  duration: 50,
  allOpen: false,
});

flipCard();

customSelect({});

selectFilter();
