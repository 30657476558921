/*

    :: Попап для отзывов v. 1.3 ::

Принимает настройки:

itemTag: ".js-review-item", - тег пункта отзыва
btnTag: ".js-open-review" - тег кнопки открытия отзыва (внутри пункта)
closeTag: "js-popup-close" - закрывает попап (опционально)
bodyClass: "is-popup-x-open" - класс на body при открытии (опционально)

Действие: по клику на btnTag добавляет на item класс "is-popup-open"

Подключение:
let popupReview = require("./popupReview");

Настройки:
popupReview({
    itemTag: ".js-review-item",
    btnTag: ".js-open-review",
    closeTag: "js-popup-close",
    bodyClass: "is-popup-x-open"
});

*/

module.exports = function (option) {

    // проверка
    if (option.itemTag && option.btnTag && document.querySelector(option.itemTag)) {
        if (document.querySelector(option.itemTag) && document.querySelector(option.btnTag)) {
            run(option);
        } else {
            if (!document.querySelector(option.itemTag)) {
                console.error("не найден контейнер");
            }
            if (!document.querySelector(option.btnTag)) {
                console.log("не найден кнопка открытия отзыва");
            }
        }
    }

    // запуск
    function run() {

        var itemList = Array.from(document.querySelectorAll(option.itemTag));
        let bodyClass = option.bodyClass || "is-popup-x-open";

        // основной код
        run.setInit = function () {

            function close() {
                 itemList.forEach (function (item) {
                    item.classList.remove('is-popup-open');
                 });
                 document.body.classList.remove(bodyClass);
            }

            itemList.forEach (function (item) {
                let btnList = Array.from(item.querySelectorAll(option.btnTag));
                
                btnList.forEach((btn)=>{
                    btn.onclick = function () {
                        close();
                        item.classList.add('is-popup-open');
                        document.body.classList.add(bodyClass);
                    }
                });

                if(option.closeTag && item.querySelectorAll(option.btnTag)) {
                    let closeEl = item.querySelector(option.closeTag);
                    closeEl.onclick = function () {
                        close();
                    }                    
                }

            });
        }

        run.setInit();
    }

}
