/*
    :: Калькулятор аренды v. 1.0 ::

Принимает настройки:

Подключение:
let arendaCalc = require("./arendaCalc");

arendaCalc({
  rootTag: ".js-arenda-card",
  optionInputTag: ".js-arenda-option",
  totalPriceTag: ".js-arenda-total-price",
})




*/

module.exports = function (option) {


  // проверка
  if (document.querySelector(option.rootTag)) {
    if (document.querySelector(option.optionInputTag) && document.querySelector(option.totalPriceTag)) {
      run(option);
    }
  }

  // запуск
  function run() {
    let rootList = document.querySelectorAll(option.rootTag);
    rootList = Array.from(rootList);

    rootList.forEach((rootEl)=>{
      let totalPriceEl = rootEl.querySelector(option.totalPriceTag);
      let optionList = rootEl.querySelectorAll(option.optionInputTag);
      let totalPrice = 0;

      optionList.forEach((optionEl)=>{
        optionEl.addEventListener("change", ()=> {

          optionList.forEach((optionEl)=>{
            if(optionEl.checked){
              totalPrice = +optionEl.dataset.price;
              totalPriceEl.innerHTML = totalPrice.toLocaleString();
            }
          });
        });
      });
    });
  }
}
