const selectFilter = () => {
  const inputElement = document.querySelector(`.js-select-input`);
  const filterContainerElement = document.querySelector(`.js-filter-select`);

  if (inputElement && filterContainerElement) {
    const filterElements = Array.from(
      document.querySelectorAll(`.js-filter-item`)
    );
    const hiddenInputElement =
      filterContainerElement.querySelector(`.js-select-h-input`);
    const selectTextElement =
      filterContainerElement.querySelector(`.js-select-input`);

    function resetField() {
      hiddenInputElement.removeAttribute(`value`);
      selectTextElement.textContent = `Выбрать объем`;
    }

    function hideElements() {
      filterElements.forEach((element) => (element.style.display = `none`));
    }

    function showRequiredElement(requiredElement) {
      requiredElement.style.display = ``;
    }

    function onInputElementChange(evt) {
      const self = evt.currentTarget;
      const requiredFilterElement = filterElements.find(
        (item) => item.dataset.value === self.textContent
      );

      resetField();
      hideElements();
      showRequiredElement(requiredFilterElement);
    }

    inputElement.addEventListener(`change`, onInputElementChange);
  }
};

export { selectFilter };
