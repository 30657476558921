export const flipCard = () => {
  const cardElements = document.querySelectorAll(`.js-flip-card`);

  if (cardElements.length) {
    cardElements.forEach((card) => {
      const cardFormBlock = card.querySelector(`.js-card-form`);
      const showButton = card.querySelector(`.js-show`);

      function getFlipped(evt) {
        if (
          !evt.target.closest(`.js-card-form`) &&
          !evt.target.classList.contains(`js-card-form`) &&
          !evt.target.classList.contains(`js-show`) &&
          !evt.target.classList.contains(`js-submit`) &&
          !evt.target.classList.contains(`flip__link`)
        ) {
          card.classList.toggle(`flip__card--flipped`);
        }

        if (!card.classList.contains(`flip__card--flipped`)) {
          cardFormBlock.classList.remove(`flip__form--show`);
        }
      }
      card.addEventListener(`click`, getFlipped);

      showButton.addEventListener(`click`, () => {
        cardFormBlock.classList.add(`flip__form--show`);
      });
    });
  }
};
