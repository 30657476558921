/*
    :: Калькулятор газификации v. 1.1 ::

Принимает настройки:

Подключение:
let chCalc = require("./popupReview");

chCalc({
  rootTag: ".js-ch-calc-root",
  basicPriceInputTag: ".js-ch-calc-basic-price",
  optionInputTag: ".js-ch-calc-option",
  totalPriceTag: ".js-ch-calc-total-price",
})




*/

module.exports = function (option) {
  // проверка
  if (document.querySelector(option.rootTag)) {
    if (
      document.querySelector(option.basicPriceInputTag) &&
      document.querySelector(option.optionInputTag) &&
      document.querySelector(option.totalPriceTag)
    ) {
      run(option);
    }
  }

  // запуск
  function run() {
    let rootList = document.querySelectorAll(option.rootTag);
    rootList = Array.from(rootList);

    rootList.forEach((rootEl) => {
      let basicPrice = +rootEl.querySelector(option.basicPriceInputTag).value;
      let totalPriceEl = rootEl.querySelector(option.totalPriceTag);
      let optionList = rootEl.querySelectorAll(option.optionInputTag);
      let totalPrice = basicPrice;

      optionList.forEach((optionEl) => {
        optionEl.addEventListener("change", () => {
          totalPrice = basicPrice;

          optionList.forEach((optionEl) => {
            if (optionEl.checked) {
              totalPrice = totalPrice + +optionEl.dataset.price;
            }
          });

          totalPriceEl.innerHTML = Math.round(totalPrice).toLocaleString();
        });
      });
    });
  }
};
