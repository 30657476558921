module.exports = async () => {
  try {
    const container = document.querySelector(`#calc__app`);
    const mainCalcFormElement = container.querySelector(`.js-main-calc`);
    const areaInputElement = mainCalcFormElement.querySelector(`.js-main-calc-area`);
    const usageInputElement = mainCalcFormElement.querySelector(`.js-main-calc-usage`);
    const costPerMonthInputElement = mainCalcFormElement.querySelector(`.js-main-calc-cost-per-month`);
    const volumeInputElement = mainCalcFormElement.querySelector(`.js-main-calc-volume`);
    const totalPriceInputElement = mainCalcFormElement.querySelector(`.js-main-calc-total-price`);

    let playingDemo = false;
    let isFocus = false;

    const COORDINATE_PLAY_DEMO = container.getBoundingClientRect().top + window.scrollY - document.documentElement.clientHeight / 2;
    const TIME_TO_COUNT = 1000;

    const Steps = {
      COST_PER_MONTH: 100,
      VOLUME: 100,
      TOTAL_PRICE: 1000,
    };

    // Скачиваем объект с ценой за литр газа
    const fetchOptions = async () => {
      const result = await fetch(`/option/`);

      if (result.status === 200) {
        return await result.json();
      }
    };

    const res = await fetchOptions();

    const price = res.answer.price;
    const volumes = res.answer.options;

    const MAX_VOLUME = +Object.keys(volumes)[Object.keys(volumes).length - 1];

    console.log(MAX_VOLUME)

    const setVolume = (value) => {
      let currentVolume;

      for (let i = 0; i < Object.keys(volumes).length; i++) {
        const square = +Object.keys(volumes)[i];
        const nextSquare = +Object.keys(volumes)[i + 1];

        if (value < square) {
          currentVolume = square;
          break;
        } else if (value >= square && value < nextSquare) {
          currentVolume = nextSquare;
          break;
        } else if (value >= square && !nextSquare) {
          currentVolume = square;
          break;
        }
      }

      return currentVolume;
    };

    // Анимация счетчика
    const outNum = (num, element, measure, step) => {
      let currentNum = parseFloat(element.value.replace(/\D/g, ""));
      const intervalTime = currentNum < +num ? Math.round(TIME_TO_COUNT / (num / step)) : Math.round(TIME_TO_COUNT / num);

      const interval = setInterval(() => {
        if (currentNum < +num) {
          currentNum = currentNum + step;

          if (currentNum >= +num) {
            currentNum = +num;
            clearInterval(interval);
          }
        } else {
          currentNum = currentNum - step;

          if (currentNum <= +num) {
            currentNum = +num;
            clearInterval(interval);
          }
        }

        element.value = `${currentNum.toLocaleString()} ${measure}`;
      }, intervalTime);
    };

    // Демо калькулятора
    class TypeWriter {
      constructor(txtElement, words, wait = 3000) {
        this.txtElement = txtElement;
        this.words = words;
        this.wait = wait;
        this.wordIndex = 0;
        this.txt = "";
        this.isDeleting = false;
        this.count = 0;
        this.type();
      }

      type() {
        let speed = 500,
          currentIndex = this.wordIndex % this.words.length,
          currentWord = this.words[currentIndex];

        if (this.isDeleting) {
          speed = speed / 2;
          this.txt = currentWord.substring(0, this.txt.length - 1);
        } else {
          speed = 500;
          this.txt = currentWord.substring(0, this.txt.length + 1);
        }

        this.txtElement.value = this.txt;if (!this.isDeleting && this.txt === currentWord) {
          outNum(+this.txtElement.value * parseFloat(usageInputElement.value) * price, costPerMonthInputElement, `₽`, Steps.COST_PER_MONTH);
          outNum(volumes[setVolume(+this.txtElement.value)].volume, volumeInputElement, `л`, Steps.VOLUME);
          outNum(volumes[setVolume(+this.txtElement.value)].price, totalPriceInputElement, `₽`, Steps.TOTAL_PRICE);
          this.isDeleting = true;
          speed = this.wait;
          this.count++;
        } else if (this.isDeleting && this.txt === "") {
          this.wordIndex++;
          this.isDeleting = false;
        }

        const timeout = setTimeout(() => this.type(), speed);

        if (this.count === 2) {
          clearTimeout(timeout);
        }

        if (isFocus) {
          clearTimeout(timeout);
        }
      }
    }

    // Проигрываем демо при скроле
    const onScrollWindow = () => {
      if (window.pageYOffset > COORDINATE_PLAY_DEMO) {
        if (!playingDemo) {
          new TypeWriter(areaInputElement, [`150`, `300`]);
          playingDemo = true;
        }
      }
    };

    const init = () => {
      onScrollWindow();

      areaInputElement.addEventListener(`change`, (evt) => {
        evt.target.value = +evt.target.value > MAX_VOLUME ? MAX_VOLUME : +evt.target.value;
        evt.target.value = +evt.target.value < 1 ? 1 : +evt.target.value;

        outNum(+evt.target.value * parseFloat(usageInputElement.value) * price, costPerMonthInputElement, `₽`, Steps.COST_PER_MONTH);
        outNum(volumes[setVolume(+evt.target.value)].volume, volumeInputElement, `л`, Steps.VOLUME);
        outNum(volumes[setVolume(+evt.target.value)].price, totalPriceInputElement, `₽`, Steps.TOTAL_PRICE);
      });

      areaInputElement.addEventListener(`focus`, () => {
        isFocus = true;
        playingDemo = true;
      });

      window.addEventListener(`scroll`, onScrollWindow);
    };

    init();
  } catch (error) {
    // console.log(error);
  }
};
