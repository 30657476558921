/*

  SimpleSlider 1.8

Простой слайдер с контролами, кнопками вперед/назад и таймером.

Действие: добавляет класс is-active к слайдам и контролам.
Добавляет класс active-slide-N к корню слайдера.

Принимает настройки:
  rootTag - корень слайдера
  slideItemTag - тег слайда
  prev: - кнопка назад
  next: - кнопка вперед
  controls: переключатели (радиобатоны)
  interval: интервал (в мс)
  slideFromAbsolute: если true, переключает слайде в режим "сдвига слайдов" -
					 обертка слайдов получает position:absolute и сдвигается влево
					 при переключении слайдов
  stopOnHover: если true, останавливает слайдкр, когда над ним находится мышь (false)

Подключение:
// слайдер
const slider = require("./simpleSlider");

// слайдер
slider({
  rootTag: ".js-index-promo-slider",
  slideItemTag: ".js-inner > *",
  prev: ".js-prev",
  next: ".js-next",
  controls: ".js-controls > *",
  interval: "4000",
  slideFromAbsolute: false,
  stopOnHover: false
});

 */

module.exports = function (option) {

	// проверка
	if (option.rootTag && option.slideItemTag) {
		if (document.querySelector(option.rootTag) && document.querySelector(option.slideItemTag)) {
			runSliderList(option);
		} else {
			if (!document.querySelector(option.rootTag)) {
				// console.log("не найден корневой элемент " + option.rootTag);
			} else {
				if (!document.querySelector(option.slideItemTag)) {
					console.error("не найдены элементы-слайды");
				}
			}
		}
	} else {
		console.error("слайдеру не заданы необходимые параметры");
	}

	// Перебор слайдеров
	function runSliderList (option) {
		let rootListNode = document.querySelectorAll(option.rootTag);
		let rootList = Array.prototype.slice.call(rootListNode, 0);

		rootList.forEach (function (rootEl) {
			option.rootEl = rootEl;
			runSlider(option);
		});
	}

	// запуск слайдера
	function runSlider (option) {
		let rootEl = option.rootEl;
		let currentSlide = 0;
    let prevSlide = 0;
		let slideItemTag = option.slideItemTag;
		let slidesNode = rootEl.querySelectorAll(option.slideItemTag);
		let slides = Array.prototype.slice.call(slidesNode, 0);

		let slideSizeArr = [];
    let slideParent = slides[0].parentNode;
    let grandParent = slideParent.parentNode;

    let mouseOnHover = false;
    let controls;

    // подсчет размеров слайдов
    if(option.slideFromAbsolute) {
      slideSizeSum = 0;
      grandParent.style.position = "relative";
      slideParent.style.position = "absolute";
      slideParent.style.left = "0px";

      let parentLeftCoord = +slideParent.getBoundingClientRect().left;

      slides.forEach (function (item, i, arr) {
        let leftCoord = +item.getBoundingClientRect().left;
        let delta = parentLeftCoord - leftCoord;
        slideSizeArr.push(delta);
      });
    }

		// проверка на кнопки
		if (option.prev) {
		  if (rootEl.querySelector(option.prev)) {
				let prevSlide = rootEl.querySelector(option.prev);

				prevSlide.onclick = function () {
				  goPrev();
				}
		  }
		}

		if (option.next) {
			if (rootEl.querySelector(option.next)) {
				let nextSlide = rootEl.querySelector(option.next);

				nextSlide.onclick = function () {
				  goNext();
				}
		  }
		}

		// проверка на controls
		if(option.controls) {
			if (rootEl.querySelectorAll(option.controls).length >0 ) {
				controls = rootEl.querySelectorAll(option.controls);
				controls = Array.from(controls);

				controls.forEach(function (item, i, arr) {
					controls[i].onclick = function () {
						currentSlide = i;
						goSlide(currentSlide);
					}
				});

			}
		}

		// переключение на 1 слайд при старте
		if (slides.length > 0) {
			goSlide(0);
		}

		// основной код
		function removeActive() {
			slides.forEach (function (item, i, arr) {
				item.classList.remove('is-active');
			});
			if (controls) {
				controls.forEach (function (item, i, arr) {
					item.classList.remove('is-active');
				});
			}
      rootEl.classList.remove("active-slide-"+prevSlide);
		}

		function goPrev() {
			if ((currentSlide-1)<0) {
				currentSlide = slides.length - 1;
			} else {
				currentSlide = (currentSlide - 1) % slides.length;
			}
			goSlide(currentSlide);
		}

		function goNext() {
			currentSlide = (currentSlide + 1) % slides.length;
			goSlide(currentSlide);
		}

		function goSlide(currentSlide) {
      removeActive ();
      slides[currentSlide].classList.add('is-active');

      if (controls) {
        controls[currentSlide].classList.add('is-active');
      }

      if(option.slideFromAbsolute) {
        slideParent.style.left = slideSizeArr[currentSlide] + "px";
      }

      rootEl.classList.add("active-slide-"+currentSlide);
      prevSlide = currentSlide;
    }

    // проверка на интервал
    if (option.interval) {
      setInterval(function () {
        if(!mouseOnHover) {
          goNext();
        }
      }, option.interval);
    }

    // положение мыши
    rootEl.addEventListener('mouseenter', function(e) {
      mouseOnHover = true;
    });

    rootEl.addEventListener('mouseleave', function(e) {
      mouseOnHover = false;
    });
	}

};


