// v1.20

const { antispam } = require("./anti-spam");
let $ = require("./jquery");
let metrika = require("./metrika")(88348421);

module.exports = function (selector) {
  // перебираем формы с нужным классом
  $.each($(selector), function () {
    // для каждой находим корень формы
    let newFormObj = $(this);
    antispam({ form: newFormObj[0] });

    let action = "";

    if (newFormObj[0].action) {
      action = newFormObj[0].action;
    }

    if (!newFormObj[0].action && newFormObj[0].getAttribute("data-url") && newFormObj[0].getAttribute("data-path")) {
      action = newFormObj[0].getAttribute("data-path") + newFormObj[0].getAttribute("data-url");
    }

    console.log(action);

    // и в нем кнопку отправить, на которое вешаем событие клик
    newFormObj.find(".js-submit").on("click", function (e) {
      e.preventDefault();

      // console.log(newFormObj[0]);
      let formData = new FormData(newFormObj[0]);

      formData.append("page", document.title);

      if (window.optionFormData) {
        for (let [name, value] of window.optionFormData) {
          formData.append(name, value);
        }
      }

      if (!newFormObj.hasClass("is-form-sending")) {
        $.ajax({
          url: action,
          type: "POST",
          processData: false,
          contentType: false,
          data: formData,
          beforeSend: function () {
            newFormObj.addClass("is-form-sending");
            $("body").addClass("is-form-sending");
            // $("body").addClass("is-popup-open");
            newFormObj.find(".js-preloader").addClass("is-active");
            newFormObj.addClass("is-preloader-active");
          },
          success: function (data) {
            console.log("  Ответ сервера: " + data);
            let success = newFormObj.find(".js-success");
            let fail = newFormObj.find(".js-fail");

            let formReset = function () {
              newFormObj[0].reset();
              success.removeClass("is-active");
              fail.removeClass("is-active");
              newFormObj.removeClass("is-form-sending");
              $("body").removeClass("is-form-sending");
              newFormObj.removeClass("is-answer-open");
              $("body").removeClass("is-answer-open");

              // очистка картинок
              if ($("#image-holder")) {
                $("#image-holder").empty();
              }

              // чтобы активировать автоформу заново,
              // поставим фокус первому полю
              newFormObj.find("input")[0].focus();
              var focusEvent = new CustomEvent("click", {
                bubbles: true,
              });
              newFormObj.find("input")[0].dispatchEvent(focusEvent);
            };

            setTimeout(function () {
              // console.log("sucGlob");
              newFormObj.find(".js-preloader").removeClass("is-active");
              newFormObj.removeClass("is-preloader-active");

              if (data == 200) {
                // console.log("success!");
                success.addClass("is-active");
                newFormObj.addClass("is-answer-open");
                $("body").addClass("is-answer-open");
                // console.log("suc1");

                // отправка метрик
                // ga('send', 'event', 'forma', 'otpravil');
                // gtag('send', 'form-send', {});

                  if (newFormObj[0].dataset.goalName) {
                  metrika.reachGoal(newFormObj[0].dataset.goalName).then(function (res) {
                    if (res.status === "OK") {
                      console.log("  Ответ метрики: цель '" + res.goal + "'" + ", статус %cOK", "color: green");
                    }
                  });
                }

                setTimeout(function () {
                  formReset();
                }, 6000);
              } else {
                console.log("fail!");
                fail.addClass("is-active");
                newFormObj.addClass("is-answer-open");
                $("body").addClass("is-answer-open");
                setTimeout(function () {
                  fail.removeClass("is-active");
                  newFormObj.removeClass("is-form-sending");
                  $("body").removeClass("is-form-sending");
                  newFormObj.removeClass("is-answer-open");
                  $("body").removeClass("is-answer-open");
                }, 3000);
              }
            }, 3000);
          },
          error: function () {
            let success = newFormObj.find(".success"),
              fail = newFormObj.find(".fail");

            setTimeout(function () {
              newFormObj.removeClass("is-form-sending");
              $("body").removeClass("is-form-sending");
              newFormObj.removeClass("is-answer-open");
              $("body").removeClass("is-answer-open");
              // success.removeClass("is-active");
              // fail.removeClass("is-active");
            }, 3000);
          },
          complete: function () {},
        });
      }

      return false;
    });
  });
};
