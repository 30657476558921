/*
    :: Скрипт передачи данных из форм калькулятора в форму попапа ::
*/

module.exports = function (option) {

  (function() {
    let popupForm, 
        closeBtn,
        oneOpenBtn,
        calcFormList;    
    let body = document.querySelector("body");

    if(document.querySelector(".js-popup-form")){
      popupForm = document.querySelector(".js-popup-form");
      closeBtn = document.querySelector(".js-popup-form-close");  

      closeBtn.addEventListener("click", (e)=> {
        body.classList.remove("is-form-popup-open");
      });            
    }         

    if(document.querySelector(".js-popup-open-btn")){
      oneOpenBtn = document.querySelector(".js-popup-open-btn");    
          
      oneOpenBtn.addEventListener("click", ()=> {
        body.classList.add("is-form-popup-open");
      });        
    }

    if(document.querySelector(".js-popup-form-calc")) {
      calcFormList = document.querySelectorAll(".js-popup-form-calc");
      calcFormList = Array.from(calcFormList);

      calcFormList.forEach((calcForm) => {
        let btn = calcForm.querySelector("[type=submit]");

        btn.addEventListener("click", (e)=> {
          e.preventDefault();
          let formData = new FormData(calcForm);
          window.optionFormData = formData;
          body.classList.add("is-form-popup-open");
        });
      });

      closeBtn.addEventListener("click", (e)=> {
        popupForm.reset();
      });  
    }
  })();
}
