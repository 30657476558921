import { KeyboardKeys } from './utils/consts';

const customSelect = ({
  selectSelector = `.js-select`,
  selectListSelector = `.js-select-content`,
  inputSelector = `.js-select-input`,
  itemSelectors = `.js-select-item`,
  hiddenInputSelector = `.js-select-h-input`,
  activeItemClass = `select__item--active`,
  openClass = `select__content--active`,
  selectIcon = `.js-icon`,
}) => {
  const selects = document.querySelectorAll(selectSelector);

  const openSelect = (selectElement, withoutClick, pressEscape) => {
    selectElement.classList.add(openClass);
    document.addEventListener(`click`, withoutClick);
    document.addEventListener(`touchstart`, withoutClick);
    document.addEventListener(`keydown`, pressEscape);
  };

  const closeSelect = (selectElement, withoutClick, pressEscape) => {
    selectElement.classList.remove(openClass);
    document.removeEventListener(`click`, withoutClick);
    document.removeEventListener(`touchstart`, withoutClick);
    document.removeEventListener(`keydown`, pressEscape);
  };

  const createEvent = (name) => {
    const event = new Event(name, { bubbles: true, cancelable: true });
    return event;
  };

  if (selects.length) {
    selects.forEach((select) => {
      const selectElement = select.querySelector(selectListSelector);
      const inputElement = select.querySelector(inputSelector);
      const itemsElements = selectElement.querySelectorAll(itemSelectors);
      const hiddenInput = select.querySelector(hiddenInputSelector);
      const icon = select.querySelector(selectIcon);

      let currentElement = selectElement.querySelector(`.${activeItemClass}`);

      const withoutSelectClickHandler = (evt) => {
        if (evt.target.closest(selectSelector) !== select) {
          closeSelect(selectElement, withoutSelectClickHandler);
          icon.classList.remove(`select__icon--active`);
        }
      };

      const onSelectPressEscape = (evt) => {
        if (evt.code === KeyboardKeys.ESCAPE) {
          closeSelect(
            selectElement,
            withoutSelectClickHandler,
            onSelectPressEscape
          );
          icon.classList.remove(`select__icon--active`);
        }
      };

      const toggleSelectStatus = () => {
        if (selectElement.classList.contains(openClass)) {
          closeSelect(
            selectElement,
            withoutSelectClickHandler,
            onSelectPressEscape
          );
          icon.classList.remove(`select__icon--active`);
        } else {
          openSelect(
            selectElement,
            withoutSelectClickHandler,
            onSelectPressEscape
          );
          icon.classList.add(`select__icon--active`);
        }
      };

      const onInputKeyDown = (evt) => {
        if (
          evt.code === KeyboardKeys.SPACE ||
          evt.code === KeyboardKeys.ENTER
        ) {
          evt.preventDefault();

          toggleSelectStatus();
        }

        if (evt.code === KeyboardKeys.ARROW_DOWN) {
          evt.preventDefault();
          itemsElements[0].focus();
        }
      };

      inputElement.addEventListener(`click`, toggleSelectStatus);

      inputElement.addEventListener(`keydown`, onInputKeyDown);

      const setCurrentItem = (evt) => {
        if (inputElement.tagName === `INPUT`) {
          inputElement.value = evt.currentTarget.textContent;
        } else {
          inputElement.textContent = evt.currentTarget.textContent;
        }
        hiddenInput.value = evt.currentTarget.dataset.value;

        if (currentElement) {
          currentElement.classList.remove(activeItemClass);
        }
        evt.currentTarget.classList.add(activeItemClass);
        currentElement = evt.currentTarget;

        inputElement.dispatchEvent(createEvent(`change`));

        closeSelect(
          selectElement,
          withoutSelectClickHandler,
          onSelectPressEscape
        );
        icon.classList.remove(`select__icon--active`);
      };

      itemsElements.forEach((item, index) => {
        const onSelectItemKeydownPress = (evt) => {
          switch (evt.code) {
            case KeyboardKeys.ARROW_DOWN:
              evt.preventDefault();
              if (index + 1 === itemsElements.length) {
                itemsElements[0].focus();
              } else {
                itemsElements[index + 1].focus();
              }
              break;
            case KeyboardKeys.ARROW_UP:
              evt.preventDefault();
              if (index - 1 < 0) {
                itemsElements[itemsElements.length - 1].focus();
              } else {
                itemsElements[index - 1].focus();
              }
              break;
            case KeyboardKeys.SPACE:
              evt.preventDefault();
              setCurrentItem(evt);
              break;
            case KeyboardKeys.ENTER:
              evt.preventDefault();
              setCurrentItem(evt);
              break;
          }
        };

        item.addEventListener(`click`, setCurrentItem);
        item.addEventListener(`keydown`, onSelectItemKeydownPress);
      });
    });
  }
};

export default customSelect;
